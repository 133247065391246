import { render } from "./qrCode.vue?vue&type=template&id=d80c54a6&scoped=true"
import script from "./qrCode.vue?vue&type=script&setup=true&lang=js"
export * from "./qrCode.vue?vue&type=script&setup=true&lang=js"

import "./qrCode.vue?vue&type=style&index=0&id=d80c54a6&lang=less&scoped=true"
script.render = render
script.__scopeId = "data-v-d80c54a6"
/* hot reload */
if (module.hot) {
  script.__hmrId = "d80c54a6"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('d80c54a6', script)) {
    api.reload('d80c54a6', script)
  }
  
  module.hot.accept("./qrCode.vue?vue&type=template&id=d80c54a6&scoped=true", () => {
    api.rerender('d80c54a6', render)
  })

}

script.__file = "src/views/verifyCode/qrCode.vue"

export default script